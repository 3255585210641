<template>
  <div>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-card :title="formTitle">
        <template #footer>
          <b-button
            type="submit"
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            Submit
          </b-button>
          <b-button
            class="ml-1"
            type="reset"
            variant="secondary"
            @click="cancelForm"
          >
            Cancel
          </b-button>
          <b-button
            class="ml-1"
            type="reset"
            variant="danger"
            @click="resetForm"
          >
            Reset
          </b-button>
        </template>
        <b-form>
          <b-row>
            <b-col>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <label>Name </label>
                  <b-form-input
                    v-model="actualForm.name"
                    size="sm"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <label>Description </label>
                  <b-form-input
                    v-model="actualForm.description"
                    size="sm"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="mb-2"
              >
                <b-form-checkbox
                  v-model="actualForm.is_active"
                  checked="false"
                  switch
                  inline
                >
                  is active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </ValidationObserver>

    <pre v-if="debugMode">{{ formMode }}</pre>
    <pre v-if="debugMode">{{ $route.params.id }}</pre>
    <pre v-if="debugMode">Sample Data: <br>{{ sampleData }}</pre>
    <pre v-if="debugMode">{{ actualForm }}</pre>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { isEmpty } from 'lodash'
import Config from './formConfig'

export default {
  components: {
    ValidationObserver, ValidationProvider,
  },
  data() {
    return {
      actualForm: {
        is_active: false,
      },
      formTitle: 'Create New Account',
      formMode: 'create',
    }
  },
  computed: {
    sampleData() {
      if (Config) {
        return Config.sampleData
      }
      return {}
    },
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    formConfig() {
      if (Config) {
        return Config.formData()
      }
      return {}
    },
  },
  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.formMode = this.$route.params.mode
    }

    if (this.formMode === 'edit'
        || this.formMode === 'remove'
        || this.formMode === 'view') {
      console.log(`${this.formMode} Mode`)
      this.$store.dispatch('accounts/getAccount', this.$route.params.id).then(({ data }) => {
        console.log(this.formMode, 'hit')
        this.actualForm = data.data
      })
    } else if (this.formMode === 'create') {
      console.log(`${this.formMode} Mode`)
    } else {
      console.log('Unknown Mode')
      this.$router.push({ name: this.parentPageNamedRoute })
    }
  },
  methods: {
    onSubmit() {
      if (this.$route.params.mode === 'edit') {
        const payload = {
          userId: this.$route.params.id,
          // password: this.actualForm.password,
          full_name: this.actualForm.full_name,
          // email: this.actualForm.email,
          phone_number: this.actualForm.phone_number.replaceAll(' ', ''),
          is_active: this.actualForm.is_active,
          // system_id: 1, // 1=merchant
        }
        this.$store.dispatch('accounts/updateAccount', payload)
      } else {
        const payload = {
          password: this.actualForm.password,
          full_name: this.actualForm.full_name,
          email: this.actualForm.email,
          phone_number: this.actualForm.phone_number.replaceAll(' ', ''),
          is_active: this.actualForm.is_active,
          system_id: 1, // 1=merchant
        }
        this.$store.dispatch('accounts/createAccount', payload)
      }
    },
    cancelForm() {
      this.$router.push({ name: this.parentPageNamedRoute })
    },
    resetForm() {
      requestAnimationFrame(() => {
        console.log(this.$refs.observer)
        this.$refs.observer.reset()
      })
    },
  },
}
</script>
